import React from 'react'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateClient, viewTenants } from '../../Redux/Action/tenant.action';
import { ToastContainer } from 'react-toastify';
import { FileEarmark } from 'react-bootstrap-icons';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const ViewTenant = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { getPerticularTenantData } = useSelector((state) => state.tenantReducer)
  useEffect(() => {
    dispatch(viewTenants(params.tenant_id));
  }, []);

  const downloadManufacture = () => {
    const documentUrl = getPerticularTenantData?.client?.manufacturer_license?.document;
    const link = document.createElement('a');
    link.href = documentUrl;
    link.target = '_blank';
    link.download = 'Manufacture license'; // Specify the desired file name here
    link.click();
  }
  const [status, setStatus] = useState('')
  useEffect(() => {
    if(getPerticularTenantData?.client?.manufacturer_license?.status){
      setStatus(getPerticularTenantData?.client?.manufacturer_license?.status)
    }
  }, [getPerticularTenantData])
  const updateManufacture = async () => {
    const payload = {
      manufacturer_license: {
        status: status
      }
    }
    dispatch(updateClient(params.tenant_id, payload))
  }
  // const location = useLocation()
  // const { tenant_id, tenant_name, unique_identifier, tenant_email, custom_domain, customer_domain, business_name, business_category } = location.state
  return (
    <>
      <div className="flex space-y-2 pb-[64px] lg:space-y-0 items-end justify-between mb-4">
        <h1 className="text-3xl font-medium text-center text-primary">
          Tenant Information
        </h1>
        <button
          className="border py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-800 text-white"
          type="submit"
          onClick={() => navigate("/tenants")}
        >
          My Tenants
        </button>
      </div>
      {/* <h1 className='text-3xl font-medium pb-4'>Tenant Information</h1> */}
      <div className='bg-white  p-3 shadow-sm rounded-sm'>
        <div className='flex items-center justify-between text-2xl space-x-2 font-semibold text-gray-900 leading-8'></div>
        <div className='text-gray-700 flex items-start '>
          <div className='grid grid-cols-1 text-sm'>
            <div className='grid grid-cols-2 mb-2'>
              <div className='px-4 py-2 font-semibold'>Tenant ID:</div>
              <div className='px-4 py-2'> {getPerticularTenantData?.tenant_id}</div>
            </div>
            <div className='grid grid-cols-2 mb-2'>
              <div className='px-4 py-2 font-semibold'>Tenant Name:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.tenant_name}</div>
            </div>
            <div className='grid grid-cols-2 mb-2'>
              <div className='px-4 py-2 font-semibold'>Unique Identifier:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.unique_identifier}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Email:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.tenant_email}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Customer Domain:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.customer_domain}</div>
            </div>
            {getPerticularTenantData?.custom_domain &&
              <div className='grid grid-cols-2'>
                <div className='px-4 py-2 font-semibold'>Custom Domain:</div>
                <div className='px-4 py-2'>{getPerticularTenantData?.custom_domain}</div>
              </div>
            }
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Business Name:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.business_name}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Business Category:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.business_category}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Verify Status:</div>
              <div className='px-4 py-2 capitalize'>{getPerticularTenantData?.verify_status?.replace("_", " ")}</div>
            </div>
            {getPerticularTenantData?.client?.additional_licenses?.length > 0 && <div>
              <div className='px-4 py-2 font-semibold'>Additional Licenses:</div>
              <div className='px-4 py-2 capitalize'>
                {getPerticularTenantData?.client?.additional_licenses?.length > 0 &&
                  getPerticularTenantData?.client?.additional_licenses?.map((elem, index) => (
                    <div key={index}>
                      {`${elem?.field_name}: ${elem?.value}`}
                    </div>
                  ))
                }
              </div>
            </div>}
            {getPerticularTenantData?.client?.manufacturer_license && <div className='grid grid-cols-2'>
              <div className='flex items-center px-4 py-2 font-semibold'>Manufacturer Licenses:</div>
              <div className='px-4 py-2'>
                <button 
                  type='button' 
                  className='secondary-btn flex items-center text-sm font-medium rounded-md text-white hover:underline'
                  onClick={downloadManufacture}
                  >
                    <FileEarmark className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' /> Uploaded document
                  </button>
              </div>
              <div className='px-4 font-semibold'>
                MarketPlace Approval:
                <FormControl className='!flex'>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={status}
                  className='table-text'
                  onClick={(e) => {if(e.target.value) {setStatus(e.target.value)}}}
                >
                  <FormControlLabel
                    className='table-text'
                    value="approved"
                    control={<Radio />}
                    label="Approved"
                  />
                  <FormControlLabel
                    className='table-text'
                    value="pending"
                    disabled
                    control={<Radio />}
                    label="Pending"
                  />
                  <FormControlLabel
                    className='table-text'
                    value="rejected"
                    control={<Radio />}
                    label="Rejected"
                  />
                </RadioGroup>
              </FormControl>
              {status !== "pending" && <buttton type='button' className='w-[100px] secondary-btn flex items-center text-sm cursor-pointer font-medium rounded-md text-white' onClick={updateManufacture}>Update</buttton>}
              </div>         
            </div>
            }
            {
              getPerticularTenantData?.delete_store_reason ?
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>Delete Store Reason:</div>
                  <div className='px-4 py-2 capitalize'>
                    <ul className='list-disc'>
                      {Object.keys(getPerticularTenantData?.delete_store_reason).map(key => (
                        <li className='ms-4' key={key}>{getPerticularTenantData?.delete_store_reason[key]}</li>
                      ))}
                    </ul>
                  </div>
                </div> : <></>
            }
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  )
}

export default ViewTenant